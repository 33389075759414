export const NavigationLinkManager = function () {
  return (
    <a href="/" id="navigationlinkmanager" className="hidden opacity-0 h-0 w-0">
      .
    </a>
  );
};

export function navigateLink(url = "/") {
  let o = document.getElementById("navigationlinkmanager") as HTMLAnchorElement;
  o.href = url;
  o.click();
}
