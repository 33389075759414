import { useState, useLayoutEffect, useRef } from "react";

type ElementParameter = string | HTMLElement;

interface Dimensions {
  element: React.MutableRefObject<HTMLElement | null>;
  width: number;
  height: number;
}

// Fonction utilitaire pour obtenir un élément DOM en fonction du paramètre fourni
function getElement(parameter: ElementParameter): HTMLElement | null {
  if (parameter instanceof HTMLElement) {
    return parameter;
  } else if (typeof parameter === "string") {
    if (parameter.startsWith("#")) {
      return document.getElementById(parameter.slice(1));
    } else if (parameter.startsWith(".")) {
      return document.querySelector(parameter);
    }
  }
  return null;
}

// Hook personnalisé pour gérer les dimensions
function useLayoutDimension(parameter: ElementParameter): Dimensions {
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });
  const elementRef = useRef<HTMLElement | null>(null);

  useLayoutEffect(() => {
    // Fonction pour mettre à jour les dimensions si l'élément référencé est valide
    function updateDimensions() {
      if (elementRef.current) {
        setDimensions({
          width: elementRef.current.offsetWidth,
          height: elementRef.current.offsetHeight,
        });
      }
    }

    // Obtenir l'élément référencé en fonction du paramètre fourni
    const element = getElement(parameter);

    if (element) {
      elementRef.current = element;
      // Mettre à jour les dimensions une fois au chargement initial
      updateDimensions();
    } else {
      // Si l'élément référencé n'est pas trouvé, initialiser les dimensions à null
      setDimensions({
        height: elementRef.current?.offsetHeight ?? 0,
        width: elementRef.current?.offsetWidth ?? 0,
      });
    }
    // S'abonner aux changements de dimensions lors du redimensionnement de la fenêtre
    window.addEventListener("resize", updateDimensions);

    // Désabonner l'écouteur lors du démontage du composant ou si l'élément référencé change
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [parameter]);

  return { element: elementRef, ...dimensions };
}

export default useLayoutDimension;
