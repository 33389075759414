import React from "react";
import LogoPrimaryWhite from "../../assets/logoprimary.svg";
import Content from "./content";

const ContentZone = () => {
  return (
    <div className="flex flex-1 flex-col min_pc_mini:min-h-[700px] min-h-[100dvh] min_pc_mini:overflow-y-auto">
      <div className="flex flex-col p-5 gap-6 bg-white w-full max-w-[400px]">
        <div className="flex min_pc_mini:hidden flex-col gap-5 pt-0 pb-1">
          <img
            src={LogoPrimaryWhite}
            alt={"LogoPrimaryWhite"}
            className="flex max-w-[300px] select-none"
          />
        </div>
        <h2 className="flex text-center select-none text-2xl font-bold">
          Conditions d'utilisations
        </h2>
        <Content />
        <a href="/login">
          <span className="flex text-[16px] font-medium">
            {"<-"} Aller à la page de connexion
          </span>
        </a>
        <div className="flex flex-row gap-[2px] items-center mt-5">
          <a href="/confidentialite">
            <span className="flex pr-1 text-[13px] underline underline-offset-2">
              Confidentialités
            </span>
          </a>
          -
          <a href="/conditions-utilisations">
            <span className="flex px-1 text-[13px] underline underline-offset-2">
              Conditions d'utilisations
            </span>
          </a>
          -
          <a href="/a-propos">
            <span className="flex pl-1 text-[13px] underline underline-offset-2">
              A propos
            </span>
          </a>
        </div>
        <div className="flex w-full pt-0 pb-1">
          <h2 className="flex text-center select-none text-[12px]">
            Copyright © 2024,{" "}
            <a href="/">
              <strong className="flex px-1">Final Invest</strong>
            </a>{" "}
            Tous droits réservés.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default ContentZone;
