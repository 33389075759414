import React from "react";
import LogoPrimaryWhite from "../../assets/logoprimarywhite.svg";

const InfoBloc = () => {
  return (
    <div className="hidden flex-col min_pc_mini:flex justify-center w-[38%] min-w-[450px] min-h-[700px] max-w-[600px] bg-primary text-white px-16 gap-12">
      <img src={LogoPrimaryWhite} alt={"LogoPrimaryWhite"} className="flex max-w-[300px]"/>
      <span className="flex text-[1.5em] text-white/70 font-medium leading-loose">
        Intégrez un programme et obtenez le financement de votre projet.
      </span>
      <span className="flex text-[18px] font-bold">Démarrez dès maintenant {'->'}</span>
    </div>
  );
};

export default InfoBloc;
