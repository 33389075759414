import React from "react";
import TextInputSign from "../../components/inputs/textInputsign";
import PasswordInputSign from "../../components/inputs/passwordInput";
import { signup } from "../../contexts/auth/functions";
import { navigateLink } from "../../components/buttons/navigationLinkManager";
import {
  ToastErrorNotifier,
  ToastSuccessNotifier,
  isPassword,
} from "../../constants";
import validator from "validator";
import { useTokenManager } from "../../hooks/useTokenManager";
import { tokenRefac } from "../../configs/apiRequest";
import { useQueryClient } from "@tanstack/react-query";
import LogoPrimaryWhite from "../../assets/logoprimary.svg";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const SignUpForm = () => {
  const queryclient = useQueryClient();
  const { updateTokens } = useTokenManager();
  const [conditionAgree, setConditionAgree] = React.useState(false);
  const [isLoading, setisLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    user_name: "",
    user_surname: "",
    user_email: "",
    user_password: "",
  });
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
    };
  }
  function checkFormData() {
    let errorOccured = false;
    if (formData.user_name.length === 0) {
      errorOccured = true;
      ToastErrorNotifier({ message: "Nom invalide" });
    }
    if (formData.user_surname.length === 0) {
      errorOccured = true;
      ToastErrorNotifier({ message: "Prenom invalide" });
    }
    if (
      formData.user_email.length === 0 &&
      !validator.isEmail(formData.user_email)
    ) {
      errorOccured = true;
      ToastErrorNotifier({ message: "Email invalide" });
    }
    if (
      formData.user_password.length === 0 &&
      !isPassword(formData.user_password)
    ) {
      errorOccured = true;
      ToastErrorNotifier({
        message:
          "Mot de passe invalide; Doit contenir minimum: 1 majuscule, 1 minuscule, 1 chiffre",
      });
    }
    return errorOccured;
  }
  function submitForm(e: any) {
    e?.preventDefault();
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    if (!conditionAgree) {
      ToastErrorNotifier({
        message: "Veuillez accepter les conditions d’utilisation !",
      });
      return;
    }
    let data = {
      user_name: formData.user_name,
      user_surname: formData.user_surname,
      user_email: formData.user_email,
      user_password: formData.user_password,
    };
    setisLoading(true);
    signup(data).then((reponse) => {
      setisLoading(false);
      if (reponse.success) {
        ToastSuccessNotifier({
          message: "Inscription reussie !",
          duration: 710,
        });
        updateTokens({
          ...tokenRefac({
            token: reponse.token,
            refreshToken: reponse.refreshToken,
          }),
        }).then(() => {
          queryclient.invalidateQueries(undefined);
          setTimeout(() => {
            navigateLink("/");
          }, 700);
        });
      } else {
        ToastErrorNotifier({ message: reponse.message.toString() });
      }
    });
  }

  return (
    <div className="flex flex-1 flex-col items-center justify-center min_pc_mini:min-h-[700px] min-h-[100dvh] max_pc_mini:overflow-y-auto">
      <form
        onSubmit={submitForm}
        className="flex flex-col p-5 gap-6 bg-white w-full max-w-[400px] py-4"
      >
        <div className="flex min_pc_mini:hidden flex-col items-center justify-center gap-5 pt-[50px] pb-1">
          <img
            src={LogoPrimaryWhite}
            alt={"LogoPrimaryWhite"}
            className="flex max-w-[230px] select-none"
          />
          <span className="flex text-black/70 select-none text-center text-[16px] font-bold">
            Intégrez un programme et obtenez le financement de votre projet.
          </span>
        </div>
        <div className="flex w-full justify-center pt-0 pb-1">
          <h2 className="flex text-center select-none min_pc_mini:text-2xl text-[22px] font-bold">
            Créez votre compte
          </h2>
        </div>
        <TextInputSign
          type={"text"}
          placeholder="Nom"
          onTextChange={onValueChange("user_name")}
          disabled={isLoading}
        />
        <TextInputSign
          type={"text"}
          placeholder="Prénom"
          onTextChange={onValueChange("user_surname")}
          disabled={isLoading}
        />
        <TextInputSign
          type={"email"}
          placeholder="Adresse email"
          onTextChange={onValueChange("user_email")}
          disabled={isLoading}
        />
        <PasswordInputSign
          placeholder="Mot de passe"
          title="Mot de passe"
          onTextChange={onValueChange("user_password")}
          disabled={isLoading}
        />

        <div className="flex flex-col -my-3 w-full">
          <FormGroup
            sx={{
              gap: 1,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={conditionAgree}
                  onChange={(e, c) => {
                    setConditionAgree(c);
                  }}
                  name="agree"
                />
              }
              label={
                <div className="flex flex-row select-none gap-[4px] items-center">
                  <span className="flex">{"J’accepte les"}</span>
                  <a href="/conditions-utilisations">
                    <span className="flex underline underline-offset-2">
                      conditions d’utilisation.
                    </span>
                  </a>
                </div>
              }
            />
          </FormGroup>
        </div>
        <button
          disabled={isLoading}
          onClick={submitForm}
          className="flex w-[99%] transition-all self-center justify-center items-center py-[15px] text-white text-[16px] font-medium bg-primary hover:bg-hoverPrimary active:bg-activePrimary rounded-md border"
        >
          <span className="flex select-none">
            Inscription{isLoading && "..."}
          </span>
        </button>
        <a
          className="flex w-[99%] select-none flex-col transition-all self-center justify-center border-2 border-primary text-primary items-center py-[10px] text-[16px] font-medium hover:bg-hoverGrey rounded-md"
          href={"/login"}
        >
          <p className="flex">Avez-vous déjà un compte ?</p>
          <p className="flex">Si oui, connectez-vous !</p>
        </a>
        <div className="flex flex-row gap-[2px] items-center self-center mt-5">
          <a href="/confidentialite">
            <span className="flex px-1 text-[13px] underline underline-offset-2">
              Confidentialités
            </span>
          </a>
          -
          <a href="/conditions-utilisations">
            <span className="flex px-1 text-[13px] underline underline-offset-2">
              Conditions d'utilisations
            </span>
          </a>
          -
          <a href="/a-propos">
            <span className="flex px-1 text-[13px] underline underline-offset-2">
              A propos
            </span>
          </a>
        </div>
        <div className="flex w-full justify-center pt-0 pb-1">
          <h2 className="flex text-center select-none text-[12px]">
            Copyright © 2024,{" "}
            <a href="/">
              <strong className="flex px-1">Final Invest</strong>
            </a>{" "}
            Tous droits réservés.
          </h2>
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;
