import React from "react";
import InfoBloc from "./infobloc";
import ContentZone from "./contentZone";

const ConditionUtilisationPage = () => {
  return (
    <div className="flex flex-1 flex-row h-[100dvh] min_pc_mini:overflow-y-auto">
      <InfoBloc />
      <ContentZone />
    </div>
  );
};

export default ConditionUtilisationPage;
