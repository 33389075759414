import React from "react";

const Content = () => {
  return (
    <b>
      Bienvenue dans notre politique de confidentialité. Votre vie privée est
      essentielle pour nous, et nous nous engageons à protéger vos données
      personnelles. Cette politique explique comment nous collectons, utilisons
      et protégeons vos informations lorsque vous utilisez notre service. 1.
      Collecte des Informations Nous collectons différentes catégories
      d’informations : • Informations fournies directement : telles que votre
      nom, adresse e-mail, numéro de téléphone. • Données collectées
      automatiquement : y compris les cookies, adresses IP, et données
      d’utilisation de l’application ou du site. • Données de tiers : obtenues
      par des intégrations ou des services partenaires. 2. Utilisation des
      Informations Nous utilisons vos données pour : • Fournir et améliorer nos
      services. • Personnaliser votre expérience utilisateur. • Communiquer avec
      vous concernant les mises à jour ou offres spéciales. • Respecter les
      exigences légales et réglementaires. 3. Cookies et Technologies Similaires
      Nous utilisons des cookies pour : • Améliorer la performance de notre
      site. • Comprendre vos préférences et comportements. • Faciliter les
      fonctionnalités de connexion automatique et de personnalisation. 4.
      Partage des Informations Vos données ne sont partagées que dans des cas
      limités : • Avec des prestataires de services qui nous aident à opérer
      notre plateforme. • Pour répondre à des obligations légales ou demandes
      des autorités. • En cas de fusion, acquisition ou vente de notre
      entreprise, vos données pourraient être transférées à la nouvelle entité.
      5. Sécurité des Données Nous mettons en œuvre des mesures de sécurité
      physiques, techniques et administratives pour protéger vos informations :
      • Chiffrement des données sensibles. • Accès restreint aux informations
      personnelles. • Tests réguliers de nos systèmes pour identifier les
      vulnérabilités. 6. Vos Droits En fonction de votre juridiction, vous avez
      certains droits, tels que : • Accéder à vos données personnelles. •
      Corriger des informations incorrectes. • Supprimer vos données, sous
      réserve de certaines exceptions. • Retirer votre consentement pour les
      communications marketing. 7. Conservation des Données Nous conservons vos
      informations personnelles aussi longtemps que nécessaire pour atteindre
      les objectifs décrits dans cette politique, sauf si une période de
      conservation plus longue est requise par la loi. 8. Services Tiers Notre
      site peut contenir des liens vers des sites ou services tiers. Nous ne
      sommes pas responsables des pratiques de confidentialité de ces tiers.
      Veuillez consulter leurs politiques respectives pour plus d’informations.
      9. Confidentialité des Enfants Nous ne collectons pas sciemment des
      informations auprès d’enfants de moins de 13 ans sans consentement
      parental vérifiable. 10. Modifications de la Politique Nous nous réservons
      le droit de modifier cette politique à tout moment. Toute modification
      sera affichée ici avec une date de mise à jour. Nous vous encourageons à
      consulter régulièrement cette page. 11. Contactez-Nous Pour toute question
      ou préoccupation concernant cette politique, vous pouvez nous contacter
      via : • E-mail : support@votreentreprise.com • Téléphone : +225 00 00 00
      00
    </b>
  );
};

export default Content;
