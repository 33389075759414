import React from "react";
import InfoBloc from "./infobloc";
import LoginForm from "./form";

const LoginPage = () => {
  return (
    <div className="flex flex-1 flex-row h-[100dvh] min_pc_mini:overflow-y-auto">
      <InfoBloc />
      <LoginForm />
    </div>
  );
};

export default LoginPage;
