import React from "react";
import { useAuth } from "../../hooks/useAuth";
import TextInputSign from "../../components/inputs/textInputsign";
import validator from "validator";
import LogoPrimaryWhite from "../../assets/logoprimary.svg";

const LoginForm = () => {
  const {
    // login,
    isLoadingLogin: isLoading,
    loginResponse,
  } = useAuth();
  const [formData, setFormData] = React.useState({
    login: "",
  });
  const [errorState, setErrorState] = React.useState({
    login: { state: false, msg: "" },
    password: { state: false, msg: "" },
    generalMsg: "",
  });
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (!slug.includes("remember")) {
        setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
      }
    };
  }
  function checkFormData() {
    let errorOccured = false;
    if (formData.login.length === 0 && validator.isEmail(formData.login)) {
      errorOccured = true;
      let msg = "Adresse email invalide";
      setErrorState((e) => ({ ...e, login: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, login: { state: false, msg: "" } }));
    }
    return errorOccured;
  }
  function submitForm(e: any) {
    e?.preventDefault();
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    // let data = {
    //   login: formData.login,
    // };
    // login(data);
  }
  return (
    <div className="flex flex-1 flex-col items-center justify-center min_pc_mini:min-h-[700px] min-h-[100dvh] max_pc_mini:overflow-y-auto">
      <form
        onSubmit={submitForm}
        className="flex flex-col p-5 gap-6 bg-white w-full max-w-[400px]"
      >
        <div className="flex min_pc_mini:hidden flex-col items-center justify-center gap-5 pt-0 pb-1">
          <img
            src={LogoPrimaryWhite}
            alt={"LogoPrimaryWhite"}
            className="flex max-w-[300px] select-none"
          />
          <span className="flex text-black/70 select-none text-center text-[18px] font-bold">
            Démarrez la réinitialisation de votre mot de passe
          </span>
        </div>
        <div className="flex w-full justify-center pt-0 pb-1">
          <h2 className="flex text-center text-2xl font-bold">
            Entrer votre identifiant de connexion
          </h2>
        </div>
        <TextInputSign
          type={"text"}
          errorState={errorState.login}
          placeholder="Adresse email"
          onTextChange={onValueChange("login")}
          disabled={isLoading || loginResponse.success}
        />
        <button
          disabled={isLoading}
          onClick={submitForm}
          style={{
            display: !loginResponse.success ? "flex" : "none",
          }}
          className="flex w-[99%] transition-all self-center justify-center items-center py-[15px] text-white text-[16px] font-medium bg-primary hover:bg-hover rounded-md border"
        >
          <span className="flex">
            {!isLoading ? "Continuer" : "Patientez..."}
          </span>
        </button>
        <div
          style={{
            display: loginResponse.success ? "flex" : "none",
          }}
          className="flex w-[99%] transition-all self-center justify-center items-center py-[9px] text-[18px] font-medium bg-green-500 rounded-[20px] shadow-sm border"
        >
          <span className="font-normal text-white">Connexion reussie !</span>
        </div>
        <a href="/login" className="flex min_pc_mini:hidden self-center">
          <span className="flex text-[16px] font-medium">
            {"<-"} Aller à la page de connexion
          </span>
        </a>
        <div className="flex flex-row gap-[2px] items-center self-center mt-5">
          <a href="/confidentialite">
            <span className="flex px-1 text-[13px] underline underline-offset-2">
              Confidentialités
            </span>
          </a>
          -
          <a href="/conditions-utilisations">
            <span className="flex px-1 text-[13px] underline underline-offset-2">
              Conditions d'utilisations
            </span>
          </a>
          -
          <a href="/a-propos">
            <span className="flex px-1 text-[13px] underline underline-offset-2">
              A propos
            </span>
          </a>
        </div>
        <div className="flex w-full justify-center pt-0 pb-1">
          <h2 className="flex text-center select-none text-[12px]">
            Copyright © 2024,{" "}
            <a href="/">
              <strong className="flex px-1">Final Invest</strong>
            </a>{" "}
            Tous droits réservés.
          </h2>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
